import lang from './lang';
import invoiz from './app/services/invoiz.service';

const releaseStage = process.env.releaseStage || process.env.NODE_ENV;
delete window.settings;

const backendApiRoot = process.env.REACT_APP_BACKEND_API_URL;
const hardwareAPIRoot = process.env.REACT_APP_HARDWARE_API_URL;
const assetsRoot = process.env.REACT_APP_ASSETS_URL;

const resourceHost = `${backendApiRoot}/api/v1/`;
const hardwareApiHost = `${
  localStorage.getItem('hardwareApi') || hardwareAPIRoot
}/api/audito`;

const resourceUrls = {
  settings: `${resourceHost}setting/`,
  user: `${resourceHost}user`,
};

const config = {
  /*dev*///sasKey: 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2050-10-02T19:58:47Z&st=2024-10-02T11:58:47Z&spr=https&sig=PRQouqPkcKHiwQ1nNC7fJwiommJVRMCFdP9tTSfyBMk%3D',
  /*prod*/sasKey: 'sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2050-10-02T16:53:48Z&st=2024-10-02T08:53:48Z&spr=https&sig=UV5y7Qaa1%2BC2%2FlGp08NzRcftkc6Jce9x82tXgyViRuY%3D',
  pdfRoute: `${assetsRoot}/documents/`,
  releaseStage,
  resourceHost,
  hardwareApiHost,
  imageConfig: {
    formats: '.jpg,.png,.jpeg',
    groupRejectionMessage:
      'ein oder mehrere Bilder entsprechen nicht den Kriterien (erlaubte Größe 20kb - 10mb)',
    sizeMessage:
      'Bild entspricht nicht den Kriterien (erlaubte Größe 20kb - 10mb)',
    maxSize: 10000000,
    minSize: 20000,
    minRes: {
      height: 100,
      width: 100,
    },
    lowResImageMessage: 'Bilder mit niedriger Auflösung nicht erlaubt',
  },
  resourceUrls,
  masterdata: {
    resourceUrl: `${resourceHost}masterdata`,

    endpoints: {
      getSite: `${resourceHost}masterdata/site`,
    },

    clientUrl: {
      single: 'masterdata',
      multiple: 'masterdatas',
    },

    routes: {
      details: `/masterdata/`,
      list: `/masterdatas`,
    },
  },
  menuItemsData: [
    {
      name: 'dashboard',
      icon: 'smarthome',
      title: 'Dashboard',
      url: '/dashboard',
      resourceKey: 'dashboard',
      submenuItems: [
        // {
        //   name: 'Dashboard',
        //   title: 'Dashboard',
        //   url: '/dashboard',
        //   mainSubmenuItem: 'Dashboard',
        //   resourceKey: 'Dashboard',
        // },
      ],
    },
  ],
  entpractices: {
    resourceUrl: `${resourceHost}entpractices`,
  },
  consultants: {
    resourceUrl: `${resourceHost}consultants`,
  },
  contact: {
    resourceUrl: `${resourceHost}contacts`,
    endpoints: {
      getClient: `${resourceHost}entpractices`,
      getContractor: `${resourceHost}contact/contractor`,
      addContractor: `${resourceHost}contact/contractor/add`,
      editContractor: `${resourceHost}contact/contractor/edit`,
      getContactsType: `${resourceHost}contacttypes`,
    },

    customerHistoryQuickfilters: [
      { name: 'all', title: 'Alle' },
      { name: 'invoice', title: 'Rechnungen' },
      { name: 'offer', title: 'Angebote' },
    ],

    clientUrl: {
      single: 'contact',
      multiple: 'contacts',
    },

    routes: {
      details: `/contact/`,
      list: `/contacts`,
    },
  },
  account: {
    resourceUrl: `${resourceHost}accounts`,
    endpoints: {
      login: `${resourceHost}identity/login`,
      forgotPassword: `${resourceHost}accounts/users/sendforgotuserpasswordlink`,
      me: `${resourceHost}identity/me`,
      roles: `${resourceHost}accounts/roles`,
      createUser: `${resourceHost}accounts/users`,
      createRole: `${resourceHost}accounts/roles`,
    },
  },
  supplies: {
    resourceUrl: `${resourceHost}supplies`,
    suppliesComments: `${resourceHost}supplies`,
    suppliesAppoitmentStatus: `${resourceHost}supplies/suppliesappointmentstatus`,
    suppliesHandover: `${resourceHost}supplies/suppliesarticlehandover/`,
    suppliesUpload: `${resourceHost}supplies/documents`,
  },
  device: {
    resourceUrl: `${resourceHost}devices`,
    endpoints: {
      deviceBatteryTypes: `${resourceHost}devicebatterytypes`,
      deviceBluetoothTypes: `${resourceHost}devicebluetoothtypes`,
      deviceDesignTypes: `${resourceHost}devicedesigntypes`,
      deviceManufacturers: `${resourceHost}devicemanufacturers`,
      deviceTechnicalLevels: `${resourceHost}devicetechnicallevels`,
      deviceWhos: `${resourceHost}devicewhos`,
    },
  },
  accessories: {
    resourceUrl: `${resourceHost}accessories`,
  },
  ticket: {
    resourceUrl: `${resourceHost}ticket`,

    endpoints: {
      contactClinet: `${resourceHost}contact/list`,
      defects: `${resourceHost}defects`,
      serviceWorks: `${resourceHost}serviceWorks`,
      hardwareComponents: `${resourceHost}hardwareComponents`,
      installations: `${resourceHost}installations`,
      sites: `${resourceHost}sites`,
      positions: `${resourceHost}positions`,
      salutations: `${resourceHost}salutations`,
      titles: `${resourceHost}titles`,
      ticketContact: `${resourceHost}contacts`,
      newTicket: `${resourceHost}ticket/new`,
      photoUrl: `${resourceHost}ticket/photo`,
    },
    fineUploader: {
      messages: {
        minSizeError: lang.ticketFileMinSizeError,
        sizeError: lang.ticketFileMaxSizeError,
        typeError: lang.ticketFileTypeError,
      },
      validation: {
        acceptFiles: ['image/jpg', 'image/jpeg', 'image/png'],
        allowedExtensions: ['jpg', 'jpeg', 'png'],
        sizeLimit: 25 * 1024 * 1024, // 25 mb
      },
      scaling: {
        sendOriginal: false,
        sizes: [{ name: '', maxSize: 3000 }],
      },
    },

    customerHistoryQuickfilters: [
      { name: 'all', title: 'Alle' },
      { name: 'invoice', title: 'Rechnungen' },
      { name: 'offer', title: 'Angebote' },
    ],

    clientUrl: {
      single: 'ticket',
      multiple: 'tickets',
    },

    routes: {
      details: `/ticket/`,
      list: `/tickets`,
    },
  },
  insurance: {
    resourceUrl: `${resourceHost}insurances`,
    insuranceResourceUrl: `${resourceHost}insurance`,
    endpoints: {
      getInsurance: `${resourceHost}insurances`,
    },
  },
  patient: {
    resourceUrl: `${resourceHost}patients`,
    endpoints: {
      getPatient: `${resourceHost}patients`,
    },
  },
  helpFaq: {
    resourceUrl: `${resourceHost}HelpFaq`,
  },
  helpDocument: {
    resourceUrl: `${resourceHost}HelpDocument`,
  },

  documentTitleSuffix: 'audito',

  emailCheck:
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,

  priceCheck: /^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]+)?$/,

  onlyNumberCheck: /^\d*\.?\d*$/,

  mobileNumberValidation: /^[6-9][0-9]{0,9}$/,

  ibanCheck: /^([A-Z]{2})(\d{2})(.+)$/,

  timeCheck: /^(\d{1,2}):(\d{2})([ap]m)?$/,
  // move into lang resource file
  // lang: {
  //   ibanHint: 'Bitte gib eine valide IBAN ein!',
  //   emailHint: 'Bitte gib eine valide E-Mail ein!',
  //   passwordHint:
  //     'Your password must contain at least 8 characters and one number or one capital letter!',
  // },
  // move into lang resource file
  // errorMsg:
  //   'Es tut uns leid, es gab einige technische Fehler! Bitte versuche es später noch einmal!',

  // move into lang resource file
  companyTypes: [
    { label: 'Kleinunternehmer', value: 'smallbusiness' },
    { label: 'Freiberufler', value: 'freelancer' },
    { label: 'Gewerbetreibender', value: 'trader' },
    { label: 'Personengesellschaft', value: 'partnership' },
    { label: 'Kapitalgesellschaft', value: 'corporation' },
  ],
  KEY_CODES: {
    ENTER: 13,
    DELETE: 46,
    TOP: 38,
    DOWN: 40,
    LEFT: 37,
    RIGHT: 39,
    ESCAPE: 27,
    BACKSPACE: 8,
  },
  currencyFormat: {
    symbol: '₹',
    format: '%v %s',
    decimal: '.',
    thousand: ',',
    precision: 2,
  },
  supportedLanguages: ['en', 'de'],
  settlementtype: [
    { label: 'Kostenfrei Gewährleistungsfall', value: 'freeOfCharge' },
    { label: 'Abrechnung erfolgt nach Aufwand', value: 'atCost' },
    {
      label: 'Erfolgt kostenfrei im Rahmen des Servicevertrags',
      value: 'serviceContract',
    },
  ],
  serviceStart: [
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
    { label: '8:00', value: '8:00' },
  ],
  ticketStatusColor: [
    { label: 'Aufgenommen', name: 'draft', value: '#DD8927', class: 'draft' },
    { label: 'Offen', name: 'open', value: '#DD8927', class: 'open' },
    {
      label: 'In Bearbeitung',
      name: 'inProgress',
      value: '#449BEC',
      class: 'inProgress',
    },
    {
      label: 'Erledigt',
      name: 'finished',
      value: '#50BC5B',
      class: 'finished',
    },
  ],
  assemblyType: [
    { label: 'D', value: 'deinstallation' },
    { label: 'I', value: 'installation' },
  ],
  dateFormat: {
    api: 'YYYY-MM-DD',
    client: 'DD.MM.YYYY',
  },
  pikaday: {
    firstDay: 1,
    i18n: {
      previousMonth: 'Zurück',
      nextMonth: 'Vor',
      months: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      weekdays: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ],
      weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    },
  },
};

const userLoggedIn = () => {
  const user = localStorage.getItem('user');
  return user ? true : false;
};

const errorHandling = (err, resources) => {
  if (err == 'ValidationError') {
    invoiz.showNotification({
      message: resources.defaultErrorMessage,
      type: 'error',
    });
  } else if (err == 'InvalidToken') {
    invoiz.showNotification({
      message: resources.InvalidToken,
      type: 'error',
    });
  } else if (err == 'ClientError') {
    invoiz.showNotification({
      message: resources.ClientError,
      type: 'error',
    });
  } else if (err == 'ServerError') {
    invoiz.showNotification({
      message: resources.ServerError,
      type: 'error',
    });
  } else if (err == 'NotFound') {
    invoiz.showNotification({
      message: resources.NotFound,
      type: 'error',
    });
  } else if (err == 'PatientAlreadyExist') {
    invoiz.showNotification({
      message: resources.PatientAlreadyExist,
      type: 'error',
    });
  } else if (err == 'ContactPersonUserAlreadyExist') {
    invoiz.showNotification({
      message: resources.errors.ContactPersonUserAlreadyExist,
      type: 'error',
    });
  } else if (err == 'ContactUserAlreadyExist') {
    invoiz.showNotification({
      message: resources.errors.ContactUserAlreadyExist,
      type: 'error',
    });
  } else if (err == 'InsuranceAlreadyExistWithIKNumber') {
    invoiz.showNotification({
      message: resources.errors.InsuranceAlreadyExistWithIKNumber,
      type: 'error',
    });
  }
};

const hardwareApiErrorHander = (err) => {
  const errMsg = err?.[0]?.detail;

  errMsg &&
    invoiz.showNotification({
      message: errMsg,
      type: 'error',
    });
};

export default {
  ...config,
  errorHandling,
  hardwareApiErrorHander,
  userLoggedIn,
};
